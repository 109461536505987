
export default class Field {
    constructor(options) {
        Object.assign(this, { ...options, options: options });

        this.temp = {};

        this.loadStorage();
    }

    loadStorage() {
        this.filter.storage.fields[this.field] ??= {};
        this.storage = this.filter.storage.fields[this.field];
        if (this.storage.show == null) {
            this.storage.show = this.options.show;
        }
    }

    saveStorage() {
        this.filter.saveStorage();
    }
}
